<script setup>
    import { reactive, watch } from 'vue'
    import { useRoute, useRouter } from 'vue-router'
    import { confirmPasswordReset, verifyPasswordResetCode } from "firebase/auth";
    import Firebase from "../firebase_settings/index.js"
    import Error from "../firebase_settings/error.js"
    import DefaultModalComponent from '../components/DefaultModalComponent.vue'
    import HeaderComponent from '../components/HeaderComponent.vue'
    import axios from 'axios';

    const route = useRoute();
    const router = useRouter();
    const auth = Firebase.auth
    //新規パスワードのリアクティブオブジェクト
    const reactiveObj = reactive({
        //新しいパスワード
        newPassword1: "",
        //新しいパスワード（確認）
        newPassword2: "",
    })
    //モーダルウィンドウに関するリアクティブオブジェクト
    const reactiveModalObj = reactive({
        //モーダル開閉
        modalFlag: false,
        //モーダルに表示するメッセージ
        modalMessage: "",
        //モーダルの再描画用のキー
        modalRenderkey: 0,
        //ローディング
        loadingFlag: false
    })
    //全ての入力フォームが入力されたときに変更ボタンを活性化させる
    const inputSomeData = () => {
        //変更ボタンの活性化フラグ
        let equalFlag = false
        //全ての入力フォームが入力されたときに変更ボタンの活性化フラグをTrueにする
        if(reactiveObj.newPassword1 && reactiveObj.newPassword2){
            equalFlag = true
        }
        //活性判定フラグTrueなら変更ボタンを活性化そうでないなら非活性化
        equalFlag? document.getElementById('commonButton').disabled = false: document.getElementById('commonButton').disabled = true
    }
    //変更ボタンを押下した際に実行
    const changePassword = () => {
        //新規パスワードの入力が一致している際
        if(reactiveObj.newPassword1 === reactiveObj.newPassword2) {
            //ローディング開始
            reactiveModalObj.loadingFlag = true
            const oobCode = route.query.oobCode
            const mode = route.query.mode
            const continueUrl = route.query.continueUrl
            //実行処理がパスワードリセットである場合
            if(mode == "resetPassword"){
                //oobCodeが正しいものであるか検証
                verifyPasswordResetCode(auth, oobCode)
                //正常時
                .then((email) => {
                    //パスワード再設定
                    confirmPasswordReset(auth, oobCode, reactiveObj.newPassword1)
                    //成功時
                    .then(async (resp) => {
                        //メールの送信
                        await pushEmail(email)
                        //ローディングの終了
                        reactiveModalObj.loadingFlag = false
                        //モーダルの表示
                        showDefaultModal()
                    })
                    .catch((error) => {
                        reactiveModalObj.loadingFlag = false
                        console.log(error.code)
                        alert(Error.errorResult(error.code, auth.currentUser))
                    });
                })
                //エラー時
                .catch((error) => {
                    //ローディング終了
                    reactiveModalObj.loadingFlag = true
                    alert(Error.errorResult('oobCode mismatch', auth.currentUser))
                });
            }
        }
        //一致していない場合
        else {
            alert(Error.errorResult('password mismatch', auth.currentUser))
        }
    }
    //パスワードが正常に再設定できた際に実行
    const pushEmail = (email) => {
        //同期処理
        return new Promise((resolve) => {
            //APIにてメール送信関数を実行
            axios({
                baseURL: process.env.VUE_APP_PUSHMAIL,
                method: 'post',
                params:{
                    email: email,
                }
            })
            //正常時（画面通知はなし）
            .then((res)=>{
                resolve(true)
            })
            //エラー時
            .catch((e)=>{
                alert(Error.errorResult(error.response.data.errorCode, auth.currentUser))
            })
        })
    }
    const showDefaultModal = () => {
        //モーダルを表示
        reactiveModalObj.modalFlag = true
        //モーダル上の表示する文面
        reactiveModalObj.modalMessage = `パスワードを変更しました。`
        //モーダル部分を再描画
        reactiveModalObj.modalRenderkey++
    }
    //モーダルウィンドウを閉じる際に実行
    const closeDefaultModal = () => {
        //モーダルウィンドウを閉じる
        reactiveModalObj.modalFlag= false
        //パスワード変更画面に遷移させる
        router.push({
            name: 'userpassword',
        });
    }
    //リアクティブオブジェクトの監視
    watch(reactiveObj, () => {
        inputSomeData()
    })
</script>

<template>
    <HeaderComponent></HeaderComponent>
    <main>
        <link rel="stylesheet" href="./togglePassword.css">
        <link href="https://use.fontawesome.com/releases/v5.6.1/css/all.css" rel="stylesheet">
        <div id="loadingDisplay" class="loading" v-show="reactiveModalObj.loadingFlag">
            <div class="load-text load-blink">処理中...</div>
        </div>
        <div class="userPassword">
            <div class="titleArea">
                <p class="title">利用者パスワード再設定</p>
            </div>
            <table class="commonTable">
                <tbody>
                    <tr>
                        <th></th>
                    </tr>
                    <tr>
                        <td class="firstTd notButtom">　新しいパスワード</td>
                    </tr>
                    <tr>
                        <td class="firstTd notTop">
                            <input type="checkbox" class="checkPassword">
                            <div class="togglePassword">
                                <input type="password" class="hideText" v-model="reactiveObj.newPassword1">
                                <!--<input type="text" class="showText" v-model="reactiveObj.password">-->
                                <!--<label for="checkPassword" class="fa fa-eye"></label>-->
                                <!--<label for="checkPassword" class="fa fa-eye-slash"></label>-->
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="firstTd notButtom">　新しいパスワード（確認）</td>
                    </tr>
                    <tr>
                        <td class="firstTd notTop">
                            <input type="checkbox" class="checkPassword">
                            <div class="togglePassword">
                                <input type="password" class="hideText" v-model="reactiveObj.newPassword2">
                                <!--<input type="text" class="showText" v-model="reactiveObj.password">-->
                                <!--<label for="checkPassword" class="fa fa-eye"></label>-->
                                <!--<label for="checkPassword" class="fa fa-eye-slash"></label>-->
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="updateArea"><button type="button" id="commonButton" @click="changePassword()" disabled>設定する</button></td>
                    </tr>
                </tbody>
            </table>
            <DefaultModalComponent :key="reactiveModalObj.modalRenderkey" :modalMessage="reactiveModalObj.modalMessage" v-show="reactiveModalObj.modalFlag" @executeMethod="closeDefaultModal"></DefaultModalComponent>
        </div>
    </main>
</template>

<style scoped>
    .userPassword {
        padding: 0 20% 0 20%;
        background-color: #e7e6e6;
    }
    .commonTable tr:first-child>*:last-child {
        border-radius: 20px 20px 0 0;
    }
    .commonTable td {
        height: 75px;
    }
    .commonTable .updateArea {
        height: 150px;
    }
    @media (max-width: 1000px) {
        .userPassword {
            padding: 0 5% 0 5%;
        }
        #commonButton {
            width: 30vw;
            left: 17%;
        }
    }
</style>
