
<script setup>
    import { computed, reactive, onMounted } from 'vue'
    import { formatToTimeZone } from 'date-fns-timezone';
    import { onAuthStateChanged } from "firebase/auth";
    import { ref as databaseRef, onValue, update, push, child, set } from "firebase/database";
    import { ref as storageRef, uploadBytes } from "firebase/storage";
    import HeaderComponent from '../components/HeaderComponent.vue'
    import NavigationBar from '../components/NavigationBar.vue'
    import DefaultModalComponent from '../components/DefaultModalComponent.vue'
    import PaginateComponent from 'vuejs-paginate-next'
    import axios from 'axios';
    import Firebase from "../firebase_settings/index.js"
    import Error from "../firebase_settings/error.js"


    const DATEFORMAT = 'YYYYMMDD'
    const TIME_ZONE_TOKYO = 'Asia/Tokyo';
    const auth = Firebase.auth
    const database = Firebase.database;
    const storage = Firebase.storage;

    //利用者情報のリアクティブオブジェクト
    const reactiveObj = reactive({
        //企業ID
        corporateId: "",
        //登録者一覧
        registrants: [],
        //更新対象の登録者
        newRegistrants: [],
        //アップロード画像
        newImages: [],
        //再レンダリング用のキー
        renderKey: 0,
        //現在のページ（デフォルトは１ページ）
        currentPage: 1,
        //姓名フラグ
        UseNameFlag1: false,
        //セイメイフラグ
        UseNameFlag2: false,
        //ローディング
        loadingFlag: false,
        //登録処理結果
        outputData: "",
        //削除フラグ
        deleteFlag: false,
        //処理完了フラグ
        execFlag: true,
        //ロールバック用のデータ
        beforeRegistrants: {},
        //モーダル開閉
        modalFlag: false,
        //モーダルに表示するメッセージ
        modalMessage: "",
        //モーダルの再描画用のキー
        modalRenderkey: 0,
        //重複チェックフラグ
        duplicateFlag: false,
        //重複時に表示するメッセージ
        duplicateMessage: "",
        //デフォルトモーダルウィンドウのCSS等をカスタマイズするオブジェクト
        otherObject: {}
    })
    //利用者の個別登録関連のリアクティブオブジェクト
    const reactiveRegistObj = reactive({
        //利用者のインデックス番号
        index: 0,
        //新規登録に関するフラグ
        newRegistFlag: false,
        //新規登録者を格納するオブジェクト
        newRegistrants: {},
        //新規登録者の画像
        newImages: []
    })
    //1ページに表示する件数
    const perPage = 10;
    //企業コードを取得
    const getCorporateId = () => {
        //同期処理
        return new Promise((resolve) => {
            //ログインチェック
            onAuthStateChanged(auth, (user) => {
                const getData = databaseRef(database, `LoginUser/${user.uid}`);
                onValue(getData, (snapshot) => {
                    resolve(snapshot.val()["CorporateId"] ? snapshot.val()["CorporateId"] : "");
                })
            })
		})
    }
    //firebaseから登録者一覧を取得
    const getRegstrants = () => {
        if(reactiveObj.corporateId) {
            const regstrantsRef = databaseRef(database, `CorporateId/${reactiveObj.corporateId}/User`);
            //firebaseから登録者を取得する
            onValue(regstrantsRef, (snapshot) => {
                const Regstrants = (snapshot.val());
                //ロールバック用に読み込み時のデータを取得
                if(reactiveObj.execFlag)
                    reactiveObj.beforeRegistrants = Regstrants
                    //読み込んだ後は変更されないようにロックをかける
                    reactiveObj.execFlag = false
                reactiveObj.registrants = [];
                for(let key in Regstrants) {
                    reactiveObj.registrants.push({
                        userId: key,
                        Id: Regstrants[key]['Id'],
                        FirstName1: Regstrants[key]['FirstName1'],
                        LastName1: Regstrants[key]['LastName1'],
                        FirstName2: Regstrants[key]['FirstName2'],
                        LastName2: Regstrants[key]['LastName2'],
                        Email: Regstrants[key]['Email'],
                        UserGroup: Regstrants[key]['UserGroup'],
                        FaceUrl: Regstrants[key]['FaceUrl'],
                        face: Regstrants[key]['FaceUrl'][0].length > 0 ?  "登録済" : "未登録",
                        LatestUpdated: Regstrants[key]['LatestUpdated']
                    })
                }
                //更新順に並べ替え
                reactiveObj.registrants = reactiveObj.registrants.sort((element, nextElement) => {
                    return (element['LatestUpdated'] > nextElement['LatestUpdated']) ? -1 : 1;
                })
            })
            reactiveObj.renderKey++
        }
    }
    //ページネーションを押下した時
    const clickCallback = (pageNum) => {
        reactiveObj.currentPage = Number(pageNum);
    }
    //CSVファイルアップロード処理
    const fileUpload = (e) => {
        reactiveObj.newRegistrants = []
        let fileChecker = true
        const file = e.target.files[0];
        //拡張子でバリデーション
        const extentionRegExp = new RegExp(/.*\.csv$/, 'i')
        if(!extentionRegExp.test(file['name'])) {
            alert(Error.errorResult('not csv', auth.currentUser))
            return;
        }
        const reader = new FileReader();
        const loadFunc = () => {
            //改行毎に配列に分解
            const lines = reader.result.split(/\r|\n|\r\n/).slice(1);
            lines.forEach(element => {
                const data = element.split(",");
                let newData = {}
                //空白分はスキップ
                if (data.length == 1) {
                    return;
                }
                //CSVの1行分に対して正規表現でチェック
                const extentionRegExp = new RegExp(/.*,.*,.*,.*,.*,[a-z\d][\w.+_-]+@[\w.-]+\.[a-z\d]+,.*,(.*\.jpg|.*\.jpeg|.*\.png|.*\.JPG|.*\.JPEG|.*\.PNG)/, 'i')
                if(!extentionRegExp.test(element)) {
                    fileChecker = false
                }
                //項目が7つ（ID、姓、名、セイ、メイ、メールアドレス、画像URL）の時
                //UserGroupとFaceUrlが複数の場合には未対応
                if(data.length >= 8) {
                    newData = {
                        'Id': data[0],
                        'LastName1': data[1],
                        'FirstName1': data[2],
                        'LastName2': data[3],
                        'FirstName2': data[4],
                        'Email': data[5],
                        'UserGroup': [data[6]],
                        'FaceUrl': [data[7]],
                    }
                };
                reactiveObj.newRegistrants.push(newData);
                //更新対象の登録者がいる場合、ラベル名を変更
                if(reactiveObj.newRegistrants.length > 0) {
                    document.getElementById("csvLabel").innerHTML = '選択済';
                }
            });
            //1行でも適していなければエラーとする
            if(!fileChecker) {
                alert(Error.errorResult('not csv format', auth.currentUser))
                reactiveObj.newRegistrants = []
                document.getElementById("csvLabel").innerHTML = 'CSVファイル参照';
                reactiveObj.renderKey++
            }
        };
        reader.onload = loadFunc;
        //文字化け防止
        reader.readAsText(file)
    }
    //画像アップロード処理
    const imageUpload = (event) => {
        try {
            const files = Array.from(event.target.files)
            //対応拡張子を.jpeg .jpg .pngにバリデーション
            const regexp = new RegExp(/.*\.jpg$|.*\.jpeg$|.*\.png$/, 'i')
            //拡張子チェッカー
            let checkFlag = false
            for(let index in files) {
                //10MBかつ対応している拡張子の画像のみを保持する
                if(regexp.test(files[index]['name']) && files[index]['size'] <= 10485760) {
                    event.target.id == "imageButtons" ? reactiveObj.newImages.push(files[index]) : reactiveRegistObj.newImages.push(files[index])
                }
                else {
                    checkFlag = true
                }
            }
            if(checkFlag) {
                alert(Error.errorResult('image format error', auth.currentUser))
            }
            //アップロード可能な画像がある場合、ラベル名を変更
            if(reactiveObj.newImages.length > 0)
                document.getElementById("imageLabels").innerHTML = '選択済'
            if(reactiveRegistObj.newImages.length > 0)
                document.getElementById("imageLabel").innerHTML = '選択済'
        }
        catch {
            alert(Error.errorResult('image reading error', auth.currentUser))
            event.target.id == "imageButtons" ? document.getElementById("imageLabels").innerHTML = '画像ファイル参照' : document.getElementById("imageLabel").innerHTML = '参照'
            reactiveObj.newImages = []
            reactiveObj.renderKey++
        }
    }
    //アップロード処理
    const uploadExec = async() => {
        //更新対象が0かつもしくは更新画像がない場合
        if(reactiveObj.newRegistrants.length == 0 && reactiveObj.newImages.length == 0) {
            alert(Error.errorResult('none files', auth.currentUser))
        }
        else {//更新対象が1人以上もしくは更新画像がある場合
            reactiveObj.loadingFlag = true
            //firebaseの更新処理を行う
            if(reactiveObj.newImages.length != 0) {
                await imageUpdate()
            }
            userUpdate()
            //ファイル選択が再発火できるようにリセットを行う
            resetExec();
        }
        reactiveObj.renderKey++;
    }
    //登録処理完了時にCSVを吐き出す際に実行
    const outputCSVData = (csvData) => {
        const filename = `result_${formatToTimeZone(Date.now(), DATEFORMAT, { timeZone: TIME_ZONE_TOKYO })}.csv`
        const bom = new Uint8Array([0xef, 0xbb, 0xbf])
        const blob = new Blob([bom, csvData], { type: "text/csv" })
        //リンク先にダウンロード用リンクを指定する
        const link = document.createElement('a')
        link.download = filename
        link.href = URL.createObjectURL(blob)
        link.click()

        //createObjectURLで作成したオブジェクトURLを開放する
        URL.revokeObjectURL(link.href)
    }
    //APIの実行
    const execFunction = (API, params) => {
        axios({
            baseURL: API,
            method: 'post',
            params: params
        })
        .then((res)=>{
            reactiveObj.beforeRegistrants = reactiveObj.registrants
            //削除実行以外の時に行う
            if(res.data.execType != "delete") {
                //ロックの解除
                reactiveObj.execFlag = true
                reactiveObj.outputData = res.data.csvData
                if(reactiveObj.outputData) {
                    showDefaultModal(`登録処理が完了しました。<br>CSVを出力しますので結果をご確認ください。`)
                }
            }
            else {
                showDefaultModal(`削除が完了いたしました。`)
            }
        })
        .catch(async (error)=>{
            //ロールバック処理
            const regstrantsRef = databaseRef(database, `CorporateId/${reactiveObj.corporateId}/User`);
            await set(regstrantsRef, reactiveObj.beforeRegistrants)
            showDefaultModal(Error.errorResult(error.response.data.errorCode, auth.currentUser))
        })
    }
    const checkMail = (targetUser) => {
        for(let registUser of reactiveObj.registrants) {
            //変更したい利用者のEメールと既存の利用者のEメールが一致しているかつ、IDが異なる場合
            if(registUser.Email == targetUser.Email && registUser.Id != targetUser.Id) {
                reactiveObj.duplicateFlag = true
                reactiveObj.duplicateMessage += `対象者:ID（${targetUser.Id}） Eメール（${targetUser.Email}）\n既存者:ID（${registUser.Id}） Eメール（${registUser.Email}）\n=================================\n`
                return true
            }
        }
        return false
    }
    //firebaseの更新処理
    const userUpdate = async () => {
        //アップロードされたファイルのヘッダーの項目数を判断する（後続処理にて別手段で取得する方向性にする）
        const header = ['LastName1', 'FirstName1', 'LastName2', 'FirstName2', 'Email', 'UserGroup', 'FaceUrl']
        const registrantsId = []
        const addRegistrants = []
        const updateRegistrants = []
        const newUserList = []
        const LatestUpdated = Date.now()
        //既存の社員番号のみを取得
        reactiveObj.registrants.forEach(registrantsElement => {
            registrantsId.push(registrantsElement['Id'])
        })
        reactiveObj.newRegistrants.forEach(newElements => {
            //該当の変更したいIDの利用者のEメールが他の登録者と重複していないか確認
            if(checkMail(newElements)) {
                return
            }
            //更新対象が未登録の場合
            if(!registrantsId.includes(newElements['Id'])) {
                addRegistrants.push(newElements)
            }
            //更新対象が登録済の場合
            else {
                //全利用者に対して更新対象を検索
                reactiveObj.registrants.forEach(registrantsElement => {
                    if(registrantsElement['Id'] == newElements['Id']){
                        let updateFlag = false
                        let changeEmailFlag = false
                        let beforeEmail = ""
                        header.forEach(headerElement => {
                            switch (headerElement) {
                                //値が配列のもの
                                case 'FaceUrl':
                                case 'UserGroup': {
                                    if(registrantsElement[headerElement].sort() != newElements[headerElement].sort()) {
                                        registrantsElement[headerElement] = newElements[headerElement]
                                        updateFlag = true
                                    }
                                    break
                                }
                                case 'Email' : {
                                    if(registrantsElement[headerElement] != newElements[headerElement]) {
                                        beforeEmail = registrantsElement[headerElement]
                                        registrantsElement[headerElement] = newElements[headerElement]
                                        updateFlag = true
                                        changeEmailFlag = true
                                    }
                                    break
                                }
                                //それ以外
                                default : {
                                    if(registrantsElement[headerElement] != newElements[headerElement]) {
                                        registrantsElement[headerElement] = newElements[headerElement]
                                        updateFlag = true
                                    }
                                }
                            }
                        })
                        //更新対象があれば別のオブジェクトに更新対象のみを格納する
                        if(updateFlag) {
                            newUserList.push({
                                execType: 'edit',
                                userKey: registrantsElement['userId'],
                                username: (newElements['LastName1'] && newElements['FirstName1']) ? newElements['LastName1'] + newElements['FirstName1'] :
                                    (newElements['LastName2'] && newElements['FirstName2']) ? newElements['LastName2'] + newElements['FirstName2'] : newElements['Email'],
                                beforeEmail: beforeEmail,
                                afterEmail: newElements['Email']
                            })
                            updateRegistrants.push(registrantsElement)
                        }
                    }
                })
            }
        })
        //登録者情報更新
        if(updateRegistrants.length > 0) {
            updateRegistrants.forEach(updateElement => {
                const userData = databaseRef(database, `CorporateId/${reactiveObj.corporateId}/User/${updateElement['userId']}`);
                const data = {
                    Id: updateElement['Id'],
                    FirstName1: updateElement['FirstName1'],
                    LastName1: updateElement['LastName1'],
                    FirstName2: updateElement['FirstName2'],
                    LastName2: updateElement['LastName2'],
                    Email: updateElement['Email'],
                    UserGroup: updateElement['UserGroup'],
                    FaceUrl: updateElement['FaceUrl'],
                    LatestUpdated: LatestUpdated
                }
                update(userData, data);
            })
        }
        //新規登録者更新
        if(addRegistrants.length > 0) {
            //User情報追加
            const userPath = `CorporateId/${reactiveObj.corporateId}/User`
            //先にDBに追加する
            addRegistrants.forEach(newElements => {
                const newUserKey = push(child(databaseRef(database), userPath)).key;
                newUserList.push({
                    execType: 'regist',
                    userKey:  newUserKey,
                    username: (newElements['LastName1'] && newElements['FirstName1']) ? newElements['LastName1'] + newElements['FirstName1'] :
                        (newElements['LastName2'] && newElements['FirstName2']) ? newElements['LastName2'] + newElements['FirstName2'] : newElements['Email'],
                    beforeEmail: '',
                    afterEmail: newElements['Email']
                })
                const data = {
                    Id: newElements['Id'],
                    FirstName1: newElements['FirstName1'] === undefined ? "" : newElements['FirstName1'],
                    LastName1: newElements['LastName1'] === undefined ? "" : newElements['LastName1'],
                    FirstName2: newElements['FirstName2'] === undefined ? "" : newElements['FirstName2'],
                    LastName2: newElements['LastName2'] === undefined ? "" : newElements['LastName2'],
                    Email: newElements['Email'],
                    UserGroup: newElements['UserGroup'] === undefined ? [""] : newElements['UserGroup'],
                    FaceUrl: newElements['FaceUrl'] === undefined ? [""] : newElements['FaceUrl'],
                    LatestUpdated: LatestUpdated
                }
                update(databaseRef(database, `${userPath}/${newUserKey}`), data)
            })
        }
        //重複したデータがある場合
        if(reactiveObj.duplicateFlag) {
            alert(Error.errorResult('duplicate email', auth.currentUser, {message: reactiveObj.duplicateMessage}))
            reactiveObj.duplicateFlag = false
            reactiveObj.duplicateMessage = ""
        }
        //利用者の更新がある場合にはAPI実行で必要な処理を行う
        if(addRegistrants.length > 0 || updateRegistrants.length > 0) {
            //.log(newUserList)
            execFunction(
                process.env.VUE_APP_MODIFYUSER,
                {
                    newUserList: newUserList,
                    corporateId: reactiveObj.corporateId
                }
            )
        }
        else {
            reactiveObj.loadingFlag = false
            reactiveObj.otherObject = {}
            if(reactiveObj.newImages.length != 0) {
                showDefaultModal(`登録画像を更新いたしました。`)
            }
            else {
                showDefaultModal(`更新内容がありませんでした。`)
            }
        }
    }
    //画像ファイルの更新
    const imageUpdate = () => {
        return new Promise((resolve) => {
            const uploadPath = `/${reactiveObj.corporateId}/User/`
            reactiveObj.newImages.forEach(async (element) => {
                const metadata = {
                    contentType: element.type
                };
                const uploadref = storageRef(storage, `${uploadPath}${element['name']}`)
                //画像をアップロード
                await uploadBytes(uploadref, element, metadata)
            })
            resolve("")
        })
    }
    //個別での利用者登録
    const registUser = () => {
        //すべての入力情報が入力されているかつ画像ファイルが選択されていること
        if(
            (
                reactiveRegistObj.newRegistrants.Id != undefined &&
                reactiveRegistObj.newRegistrants.FirstName1 != undefined &&
                reactiveRegistObj.newRegistrants.LastName1 != undefined &&
                reactiveRegistObj.newRegistrants.FirstName2 != undefined &&
                reactiveRegistObj.newRegistrants.LastName2 != undefined &&
                reactiveRegistObj.newRegistrants.Email != undefined
            )
        ) {
            //画像ファイルのみはこちらで更新を行う
            //画像ファイルが新規に選択されている場合にはそちらを優先する
            try {
                reactiveRegistObj.newRegistrants.FaceUrl = reactiveRegistObj.newImages[0]['name']
            }
            //画像ファイルが選択されていない場合には既存の画像ファイルを使用する
            catch {
                reactiveRegistObj.newRegistrants.FaceUrl = reactiveObj.registrants[reactiveRegistObj.index].FaceUrl
            }
            //更新対象は一人しかいないが既存処理に合わせてオブジェクトを格納する
            reactiveObj.newRegistrants = [reactiveRegistObj.newRegistrants]
            reactiveObj.newImages = reactiveRegistObj.newImages
            //※画像変更した場合、不一致と出る(恐らく、アップロード前に完了している？)
            reactiveObj.otherObject = {
                confirm: '登録',
                modalWidth: "40%",
            }
            const message = `以下の内容で登録します。<br>よろしいですか？<br><br>
                ID: ${reactiveRegistObj.newRegistrants.Id}<br>
                姓名: ${reactiveRegistObj.newRegistrants.LastName1} ${reactiveRegistObj.newRegistrants.FirstName1}<br>
                フリガナ: ${reactiveRegistObj.newRegistrants.LastName2} ${reactiveRegistObj.newRegistrants.FirstName2}<br>
                メールアドレス: ${reactiveRegistObj.newRegistrants.Email}<br>
                画像ファイル: ${reactiveRegistObj.newRegistrants.FaceUrl}`
            //更新の実行フラグ
            reactiveRegistObj.newRegistFlag = true
            showDefaultModal(message)
        }
        else {
            alert(Error.errorResult('none data', auth.currentUser))
        }
    }
    //既存の値をオブジェクトに格納する
    const inputRegist = (index) => {
        reactiveRegistObj.index = index
        reactiveRegistObj.newRegistrants = {
            Id: reactiveObj.registrants[index].Id,
            FirstName1: reactiveObj.registrants[index].FirstName1,
            LastName1: reactiveObj.registrants[index].LastName1,
            FirstName2: reactiveObj.registrants[index].FirstName2,
            LastName2: reactiveObj.registrants[index].LastName2,
            UserGroup: ['All'],
            Email: reactiveObj.registrants[index].Email,
            FaceUrl: reactiveObj.registrants[index].FaceUrl
        }
        //実行後は上にスクロールさせる
        window.scroll({top: 0, behavior: 'smooth'});
    }
    //利用者の削除する際に実行
    const deleteRegist = (index) => {
        //デフォルトモーダルのデザインを書き加える用のオブジェクト
        reactiveObj.otherObject = {
            index: index,
            confirm: '削除する',
            modalWidth: "40%",
        }
        const message = `以下のユーザーを削除します。<br>よろしいですか？<br><br>ID:${ reactiveObj.registrants[index].Id}<br>姓名: ${reactiveObj.registrants[index].LastName1} ${reactiveObj.registrants[index].FirstName1}<br>フリガナ: ${reactiveObj.registrants[index].LastName2} ${reactiveObj.registrants[index].FirstName2}<br>メールアドレス: ${reactiveObj.registrants[index].Email}<br>画像ファイル: ${reactiveObj.registrants[index].FaceUrl[0]}`
        showDefaultModal(message)
        //削除フラグを有効化
        reactiveObj.deleteFlag = true
        //再描画
        reactiveObj.renderKey++
    }
    //処理実行後にデータをリセットする時に実行する
    const resetExec = () => {
        reactiveObj.newRegistrants = []
        reactiveObj.newImages = []
        document.getElementById("csvLabel").innerHTML = 'CSVファイル参照';
        document.getElementById("imageLabels").innerHTML = '画像ファイル参照';
        reactiveRegistObj.newRegistFlag = false
        reactiveRegistObj.newRegistrants = {}
        reactiveRegistObj.newImages = []
        document.getElementById("imageLabel").innerHTML = '参照';
        reactiveObj.renderKey++
    }
    //デフォルトのモーダルを開く際に実行
    const showDefaultModal = (message) => {
        //ローディング終了
        reactiveObj.loadingFlag = false
        //モーダルを表示
        reactiveObj.modalFlag = true
        //モーダル上の表示する文面
        reactiveObj.modalMessage = message
        //モーダル部分を再描画
        reactiveObj.modalRenderkey++
    }
    //デフォルトのモーダルを閉じる際に実行
    const closeDefaultModal = (editFlag) => {
        //登録完了時のCSV吐き出す処理
        if(reactiveObj.outputData) {
            outputCSVData(reactiveObj.outputData)
            reactiveObj.outputData = ""
        }
        //削除実行フラグが有効化かつ同意している場合
        if(reactiveObj.deleteFlag && editFlag) {
            //ローディング開始
            reactiveObj.loadingFlag = true
            //削除対象のインデックス番号を取得
            const index = reactiveObj.otherObject.index
            //削除対象のidを取得
            const UserId = reactiveObj.registrants[index].userId
            //削除対象のユーザグループを取得
            const UserGroups = reactiveObj.registrants[index].UserGroup
            //削除対象のEメール
            const Email = reactiveObj.registrants[index].Email
            //削除対象のDB
            const regstrantsRef = databaseRef(database, `CorporateId/${reactiveObj.corporateId}/User/${UserId}`)
            //削除する
            set(regstrantsRef, {})
            //全てのユーザグループから削除したユーザを削除する
            for(let UserGroup of UserGroups) {
                let userGroupRef = databaseRef(database, `CorporateId/${reactiveObj.corporateId}/UserGroup/${UserGroup}/UserId/${UserId}`)
                set(userGroupRef, {})
                let userGroupUpdateRef = databaseRef(database, `CorporateId/${reactiveObj.corporateId}/UserGroup/${UserGroup}`)
                update(userGroupUpdateRef, {LatestUpdated: Date.now()})
            }
            //削除APIの実行
            execFunction(
                process.env.VUE_APP_DELETEUSER,
                {
                    afterEmail: Email,
                    corporateId: reactiveObj.corporateId
                }
            )
            //削除フラグを無効化
            reactiveObj.deleteFlag = false
            reactiveObj.otherObject = {}
        }
        //個別登録フラグが有効化かつ同意の場合
        if(reactiveRegistObj.newRegistFlag && editFlag) {
            reactiveObj.otherObject = {}
            //既存処理と同様に登録処理を行う
            uploadExec()
        }
        //モーダルを閉じる
        reactiveObj.modalFlag = false
    }
    //アプリ設定を取得する
    const getAppsetting = () => {
        const settingsRef = databaseRef(database, `CorporateId/${reactiveObj.corporateId}/AppSettings`)
            //firebaseからアプリ情報を取得する
            onValue(settingsRef, (snapshot) => {
                const settings = (snapshot.val())
                reactiveObj.UseNameFlag1 = settings["UseName1"]
                reactiveObj.UseNameFlag2 = settings["UseName2"]
            })
    }
    //Nページ目に表示する利用者情報を取得
    const getItems = computed(() => {
        let current = reactiveObj.currentPage * perPage;
        let start = current - perPage;
        return reactiveObj.registrants.slice(start, current);
    })
    //現在のページ数を取得
    const getPageCount = computed(() => {
        return Math.ceil(reactiveObj.registrants.length / perPage) ? Math.ceil(reactiveObj.registrants.length / perPage) : 1
    })
    onMounted(async () => {
        reactiveObj.corporateId = await getCorporateId()
        getRegstrants()
        getAppsetting()
    })
</script>

<template>
    <HeaderComponent></HeaderComponent>
    <NavigationBar></NavigationBar>
    <main>
        <div id="loadingDisplay" class="loading" v-show="reactiveObj.loadingFlag">
            <div class="load-text load-blink">処理中...</div>
        </div>
        <div class="userRegistration" :key="reactiveObj.renderKey">
            <div class="noneDesktop">
                <div class="titleArea">
                    <p class="title">利用者登録</p>
                </div>
                <div class="inputArea">
                    <div class="inputUserArea">
                        <div id="inputUser1">
                            <p>ID</p>
                            <input type="text" id="inputId" v-model="reactiveRegistObj.newRegistrants.Id">
                        </div>
                        <div id="inputUser2">
                            <p>姓</p>
                            <input type="text" id="inputLastName1" v-model="reactiveRegistObj.newRegistrants.LastName1">
                        </div>
                        <div id="inputUser3">
                            <p>名</p>
                            <input type="text" id="inputFirstName1" v-model="reactiveRegistObj.newRegistrants.FirstName1">
                        </div>
                        <div id="inputUser4">
                            <p>セイ</p>
                            <input type="text" id="inputLastName2" v-model="reactiveRegistObj.newRegistrants.LastName2">
                        </div>
                        <div id="inputUser5">
                            <p>メイ</p>
                            <input type="text" id="inputFirstName2" v-model="reactiveRegistObj.newRegistrants.FirstName2">
                        </div>
                    </div>
                    <div class="inputUserArea">
                        <div id="inputUser6">
                            <p>メールアドレス</p>
                            <input type="text" id="inputMail" v-model="reactiveRegistObj.newRegistrants.Email">
                        </div>

                        <div id="inputUser7">
                            <p>画像ファイル</p>
                            <label id="imageLabel">参照<input type="file" id="imageButton" @change="imageUpload"></label>
                        </div>
                        <div id="inputUser8">
                            <p>　</p>
                            <input type="button" id="inputExec" @change="oneUploadExec" value="確認" @click="registUser">
                        </div>
                    </div>
                </div>
                <div class="userRegistArea">
                    <div class="subTitleArea">
                        <p class="subTitle">利用者一括登録</p>
                    </div>
                    <div class="userBulkRegist">
                        <label id="csvLabel">CSVファイル参照<input type="file" id="csvButton" @change="fileUpload"></label>
                        <label id="imageLabels">画像ファイル参照<input type="file" id="imageButtons" @change="imageUpload" multiple></label>
                        <label id="uploadLabel">アップロード<button type="button" id="uploadButton" @click="uploadExec"></button></label>
                    </div>
                </div>
            </div>
            <div class="registrants">
                <div class="subTitleArea">
                    <p class="subTitle">登録者一覧</p>
                </div>
                <div class="tableArea">
                    <table class="commonTable">
                        <tbody>
                            <tr>
                                <th>ID</th>
                                <th v-if="reactiveObj.UseNameFlag1">姓名</th>
                                <th v-if="reactiveObj.UseNameFlag2">フリガナ</th>
                                <th class="leftSideTh">メールアドレス</th>
                                <th>特徴量データ</th>
                                <th class="noneDesktop">編集</th>
                                <th class="noneDesktop">削除</th>
                            </tr>
                            <tr v-for="(data, key) in getItems" :key="key">
                                <td>{{ data.Id }}</td>
                                <td v-if="reactiveObj.UseNameFlag1">{{ data.LastName1 }} {{ data.FirstName1 }}</td>
                                <td v-if="reactiveObj.UseNameFlag2">{{ data.LastName2 }} {{ data.FirstName2 }}</td>
                                <td class="leftSideTd">{{ data.Email }}</td>
                                <td>{{ data.face }}</td>
                                <td class="noneDesktop"><button type="button" class="editButton" ><img src="../assets/pencil.png" id="pencilIcon" @click="inputRegist((reactiveObj.currentPage-1)*perPage+key)"></button>
                                </td>
                                <td class="noneDesktop"><button type="button" class="deleteButton"><img src="../assets/delete.png" id="deletelIcon" @click="deleteRegist((reactiveObj.currentPage-1)*perPage+key)"></button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="paginationArea">
                <!--
                <PaginateComponent
                    :page-count="getPageCount"
                    :click-handler="clickCallback"
                    :page-range="5"
                    :prev-text="reactiveObj.currentPage >= 2 ? '<' : ''"
                    :next-text="reactiveObj.currentPage < parseInt(reactiveObj.registrants.length / 10) + 1 ? '>' : ''"
                    :container-class="'pagination'"
                    :page-class="'page-item'">
                </PaginateComponent>
                -->
                <PaginateComponent
                    :page-count="getPageCount"
                    :click-handler="clickCallback"
                    :page-range="5"
                    :prev-text="'<'"
                    :next-text="'>'"
                    :container-class="'pagination'"
                    :page-class="'page-item'">
                </PaginateComponent>
            </div>
            <DefaultModalComponent
                :key="reactiveObj.modalRenderkey"
                :modalMessage="reactiveObj.modalMessage"
                :otherObject="reactiveObj.otherObject"
                v-show="reactiveObj.modalFlag"
                @executeMethod="closeDefaultModal">
            </DefaultModalComponent>
        </div>
    </main>
</template>

<style scoped>
    .userRegistration {
        padding: 0 5% 0 25%;
        background: #e7e6e6;
    }
    .inputArea {
        padding: 20px 5% 20px 5%;
        background-color: #F5F5F5;
        width: 90%;
    }
    .inputUserArea {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 0 0 10px 0;
        gap: 3%;
    }
    [id^="inputUser"] {
        width: 93%;
    }
    #inputUser6 {
        width: 310%;
    }
    [id^="inputUser"] input {
        width: 100%;
        height: 55px;
        border-radius: 10px;
        border: 2px solid #999;
    }
    [id^="inputUser"] p {
        text-align: left;
    }
    #inputMail {
        width: 100%;
    }
    #imageLabel {
        width: 100%;
        height: 55px;
        border: 3px solid #d7d7d7;
        border-radius: 10px;
        background-color: #d7d7d7;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    #inputFile:active {
        transform: translate(0,2px);
    }
    #inputExec {
        width: 102%;
        height: 60px;
        padding: 0 12px 0 0;
        border: none;
        background-color: #7CD5F5;
    }

    .userBulkRegist {
        display: flex;
        height: 100px;
        align-items: center;
        justify-content: space-between;
        gap: 10%;
        background-color: #F5F5F5;
        padding: 0 5% 0 5%;
    }
    #csvButton, #imageButton, #imageButtons, #uploadButton, #resetButton {
        display: none;
    }
    .userBulkRegist #csvLabel {
        position: relative;
        width: 30%;
        height: 60%;
        font-size: 100%;
        line-height: 55px;
        border-radius: 10px;
        border: solid 3px #d7d7d7;
        background-color: #d7d7d7;
    }
    .userBulkRegist #imageLabels {
        width: 30%;
        height: 60%;
        font-size: 100%;
        line-height: 55px;
        border-radius: 10px;
        border: solid 3px #d7d7d7;
        background-color: #d7d7d7;
    }
    .userBulkRegist #uploadLabel {
        width: 30%;
        height: 60%;
        font-size: 100%;
        line-height: 55px;
        border-radius: 10px;
        border: solid 3px #7CD5F5;
        background-color: #7CD5F5;
    }
    .userBulkRegist #resetLabel {
        width: 30%;
        font-size: 100%;
        border-radius: 10px;
        border: solid 3px #ed68c1;
        background-color: #edcce2;
    }
    @media (max-width: 1000px) {
        .userRegistration{
            padding: 0 5% 0 5%;
        }
        .noneDesktop {
            display: none;
        }
        .tableArea {
            overflow-x: scroll;
        }
        .commonTable {
            width: 1000px;
        }
        .commonTable tr:first-child>*:nth-child(5) {
            border-radius: 0 20px 0 0;
        }
        .subTitleArea {
            height: 60px;
        }
        .subTitle {
            padding: 0 0 0 0;
        }
    }
</style>
