<template>
    <router-view/>
</template>

/*共通部*/
<style>
    .contents {
        font-size: 30px;
        margin: 50px 0 0 0;
    }
    #app {
        background: #e7e6e6;
        text-align: center;
    }
    * {
        font-family: "Noto Sans JP", "Hiragino Kaku Gothic ProN", "Meiryo", sans-serif;
        font-size: 16px;
        line-height: 1.7;
        color: #4a4a4a;
        margin: 0;
        padding: 0;
    }
    html {
        background: #e7e6e6;
        min-height: calc(100vh - 46vh);
        position: relative;
        box-sizing: border-box;
    }
    /* コンテンツ関連 */
    .title {
        font-size: 20px;
        font-weight: bold;
    }
    .titleArea {
        height: 60px;
    }
    .subTitle {
        padding: 5% 0 2% 0;
        font-size: 20px;
        font-weight: bold;
    }
    button {
        border-radius: 10px;
        padding: 6px 20px 8px;
        width: auto;
        height: auto;
        cursor: pointer;
    }
    button:active {
        transform: translate(0,2px);
    }
    .btnStandard {
        background: #7CD5F5;
        border: 3px solid #7CD5F5;
    }
    .btnStandard:hover {
        background: #7CD5F5;
        border: 3px solid #7CD5F5;
    }
    input {
        height: 40px;
        text-indent: 1em;
    }
    /* ページネーション関係↓ */
    .pagination {
        --bs-pagination-padding-x: 0.75rem;
        --bs-pagination-padding-y: 0.375rem;
        --bs-pagination-font-size: 1rem;
        --bs-pagination-color: var(--bs-link-color);
        --bs-pagination-bg: var(--bs-body-bg);
        --bs-pagination-border-width: var(--bs-border-width);
        --bs-pagination-border-color: var(--bs-border-color);
        --bs-pagination-border-radius: var(--bs-border-radius);
        --bs-pagination-hover-color: var(--bs-link-hover-color);
        --bs-pagination-hover-bg: var(--bs-tertiary-bg);
        --bs-pagination-hover-border-color: var(--bs-border-color);
        --bs-pagination-focus-color: var(--bs-link-hover-color);
        --bs-pagination-focus-bg: var(--bs-secondary-bg);
        --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
        --bs-pagination-active-color: #fff;
        --bs-pagination-active-bg: #0d6efd;
        --bs-pagination-active-border-color: #0d6efd;
        --bs-pagination-disabled-color: var(--bs-secondary-color);
        --bs-pagination-disabled-bg: var(--bs-secondary-bg);
        --bs-pagination-disabled-border-color: var(--bs-border-color);
        display: flex;
        padding-right: 0;
        list-style: none;
        position: relative;
        text-align: left;
        padding: 5% 0 5% 0;
        justify-content: center;
    }
    .page-link {
        position: relative;
        display: block;
        padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
        font-size: var(--bs-pagination-font-size);
        color: var(--bs-pagination-color);
        text-decoration: none;
        background-color: var(--bs-pagination-bg);
        border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
        transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
        cursor: pointer;
    }
    @media (prefers-reduced-motion:reduce) {
        .page-link {
            transition: none
        }
    }
    .page-link:hover {
        z-index: 2;
        color: var(--bs-pagination-hover-color);
        background-color: var(--bs-pagination-hover-bg);
        border-color: var(--bs-pagination-hover-border-color)
    }
    .page-link:focus {
        z-index: 3;
        color: var(--bs-pagination-focus-color);
        background-color: var(--bs-pagination-focus-bg);
        outline: 0;
    }
    .active>.page-link, .page-link.active {
        z-index: 3;
        color: var(--bs-pagination-active-color);
        background-color: var(--bs-pagination-active-bg);
        border-color: var(--bs-pagination-active-border-color)
    }
    .disabled>.page-link, .page-link.disabled {
        color: var(--bs-pagination-disabled-color);
        pointer-events: none;
        background-color: var(--bs-pagination-disabled-bg);
        border-color: var(--bs-pagination-disabled-border-color)
    }
    .page-item:not(:first-child) .page-link {
        margin-right: calc(var(--bs-border-width) * -1)
    }
    .page-item:first-child .page-link {
        border-top-right-radius: var(--bs-pagination-border-radius);
        border-bottom-right-radius: var(--bs-pagination-border-radius);
        color: #0000EE;
        text-decoration: underline;
    }
    .page-item:last-child .page-link {
        border-top-left-radius: var(--bs-pagination-border-radius);
        border-bottom-left-radius: var(--bs-pagination-border-radius);
        color: #0000EE;
        text-decoration: underline;
    }
    .pagination-lg {
        --bs-pagination-padding-x: 1.5rem;
        --bs-pagination-padding-y: 0.75rem;
        --bs-pagination-font-size: 1.25rem;
        --bs-pagination-border-radius: var(--bs-border-radius-lg)
    }
    .pagination-sm {
        --bs-pagination-padding-x: 0.5rem;
        --bs-pagination-padding-y: 0.25rem;
        --bs-pagination-font-size: 0.875rem;
        --bs-pagination-border-radius: var(--bs-border-radius-sm)
    }
    /* ページネーション関係↑ */
    /* ローディング関係↓ */
    .loading {
        /* 画面最大 */
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        /* 背景色（黒、不透明度80%） */
        background-color: #000000;
        opacity: 0.8;
        /* フレックスコンテナ（縦並べ、横中央、縦中央） */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /* 表示を一番上 */
        z-index: 999;
        /* 選択付加 */
        user-select: none;
    }
    .load-text {
        color: white;
        font-size: 16pt;
    }
    .load-blink {
        animation: blink 1s linear infinite;
    }
    @keyframes blink {
        0%, 100% {
            opacity: 1;
        }
        50% {
            opacity: 0;
        }
    }
    /* ローディング関係↑ */
    /* テーブル関連 */
    .commonTable {
        width: 100%;
        margin: 0 0 0 0;
        border-collapse: collapse;
        border: none;
    }
    .commonTable tr {
        height: 60px;
    }
    .commonTable th {
        height: 75px;
        color: #F5F5F5;
        background-color: #818181;
    }
    .commonTable td {
        border-top: solid 1px;
        background-color: white;
    }
    .commonTable .notButtom {
        border-bottom: none;
    }
    .commonTable .notTop {
        border-top: none;
    }
    .commonTable .updateArea {
        height: 150px;
    }
    .commonTable tr:first-child>*:first-child{
        border-radius: 20px 0 0 0;
    }
    .commonTable tr:first-child>*:last-child{
        border-radius: 0 20px 0 0;
    }
    .firstTd {
        text-align: left;
    }
    .leftSideTh {
        text-align: left;
        padding: 0 0 0 30px;
    }
    .leftSideTd {
        text-align: left;
        padding: 10px 0 10px 30px;
    }
    .halfTh {
        width: 50%;
    }
    /* パスワード関連 */
    /*.fa-eye:before {
        display: inline;
    }
    .fa-eye-slash:before {
        display: none;
    }
    #checkPassword:checked + .togglePassword > .fa-eye:before {
        display: none;
    }
    #checkPassword:checked + .togglePassword > .fa-eye-slash:before {
        display: inline-block;
    }
    #checkPassword:checked + .togglePassword > .hideText {
        display: none;
    }
    #checkPassword:checked + .togglePassword > .showText {
        display: inline-block;
    }*/
    .checkPassword {
        display: none;
        border: none;
    }
    .hideText, .showText {
        width: 100%;
        border: none;
        outline: none;
        /*padding: 0 20px 0 0;*/
        text-align: left;
        background-color: #EEEEEF;
        border-radius: 10px;
    }
    .togglePassword {
        margin: 0 2% 0 2%;
        width: 96%;
        text-align: left;
        background-color: #EEEEEF;
        border-radius: 10px;
    }
    .showText {
        display: none;
    }
    .hideText {
        display: inline;
        width: 100%;
    }
    /* ボタン関連 */
    button[id^="commonButton"] {
        width: 25%;
        height: 50%;
        border: solid 1px #7CD5F5;
        border-radius: 10px;
        transition: 0.4s;
        background-color: #7CD5F5;
        text-align: center;
        position: relative;
        top: 25%;
        left: 12.5%;
        transform: translate(-50%, -50%);
    }
    button[id^="commonButton"]:hover {
        background: #7CD5F5;
        cursor: pointer;
    }
    button[id^="commonButton"]:disabled {
        border: solid 3px #3f3f3f;
        background: #797979;
        color: #F5F5F5;
    }
    .editButton , .deleteButton {
        background: transparent;
        border: none;
        outline: none;
        box-shadow: none;
    }
    #pencilIcon {
        width: 25px;
    }
    #deletelIcon {
        width: 20px;
    }
    /* モーダル関連 */
    .modal {
        padding: 30px 30px 30px 30px;
        box-sizing: border-box;
        border: 2px solid;
        background: #F5F5F5;
        z-index: 5;
        display: flex;
        text-align: center;
        position: fixed;
        width: 60%;
        height: 70%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 20px;
        flex-direction: column;
        align-items: left;
        justify-content: left;
        gap: 5%;
    }
    .modalOverlay {
        z-index: 4;
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 120%;
        background-color: rgba(0, 0, 0, 0.50);
    }
    .modalTitle {
        font-size: 16px;
        font-weight: bold;
    }
    .modalInfo {
        display: flex;
        flex-direction: row;
        padding: 0 15% 0 15%;
    }
    .modalInfo p {
        position: relative;
        top: 25%;
        width: 300%;
        white-space: nowrap;
        font-size: 16px;
        display: flex;
        flex-direction: row;
        align-items: left;
        justify-content:left;
    }
    .modalInfo .modalLocktime, .modalTerminal, .modalLocation, .modalTerminalType, .editConnection {
        height: 50px;
        width: 200%;
        border-radius: 10px;
        border: solid 3px #d7d7d7;
        background-color: #d7d7d7;
    }
    @media (max-width: 1000px) {
        .modalInfo .modalLocktime, .modalTerminal, .modalLocation, .modalTerminalType, .editConnection {
            width: 100%;
        }
    }
    #_bownow_iframe_sid_e918e9960bf59aad74e5 {
        display: none;
    }
</style>
