<script setup>
    import { reactive, watch } from 'vue'
    import { useRoute } from 'vue-router'
    import { updatePassword, signInWithEmailAndPassword, signOut } from "firebase/auth";
    import { ref as databaseRef, onValue, query, equalTo, orderByChild } from "firebase/database";
    import DefaultModalComponent from '../components/DefaultModalComponent.vue'
    import HeaderComponent from '../components/HeaderComponent.vue'
    import axios from 'axios';
    import Firebase from "../firebase_settings/index.js"
    import Error from "../firebase_settings/error.js"

    const auth = Firebase.auth
    const database = Firebase.database;
    const route = useRoute()
    //パスワード変更に必要な値のリアクティブオブジェクト
    const reactiveObj = reactive({
        //現在のメールアドレス
        email: "",
        //現在のパスワード
        password: "",
        //新しいパスワード
        newPassword1: "",
        //新しいパスワード（確認）
        newPassword2: "",
    })
    //モーダルウィンドウ関連のリアクティブオブジェクト
    const reactiveModalObj = reactive({
        //モーダル開閉
        modalFlag: false,
        //モーダルに表示するメッセージ
        modalMessage: "",
        //モーダルの再描画用のキー
        modalRenderkey: 0,
        //モーダルに関する特殊操作に用いる
        optionFlag: false,
        //ローディング
        loadingFlag: false
    })
    // ログアウト処理
    const logOut = () => {
        // ユーザー情報とイベント内容をログに記録
        signOut(auth)
        .then(() => {})
        .catch((error) => {
            alert(Error.errorResult('logout error', auth.currentUser))
        });
    }
    //パスワードが正常に再設定できた際に実行
    const pushEmail = (email) => {
        //同期処理
        return new Promise((resolve) => {
            //APIにてメール送信関数を実行
            axios({
                baseURL: process.env.VUE_APP_PUSHMAIL,
                method: 'post',
                params:{
                    email: email,
                }
            })
            //正常時（画面通知はなし）
            .then((res)=>{
                resolve(true)
            })
            //エラー時
            .catch((e)=>{
                alert(Error.errorResult(error.response.data.errorCode, auth.currentUser))
            })
        })
    }
    //企業コードのチェック
    const checkCorporate = () => {
        //同期処理
        return new Promise((resolve) => {
			const getData = databaseRef(database, `CorporateId/${route.query.CorporateId}/User`);
            const checkData = query(getData, orderByChild("Email"), equalTo(reactiveObj.email))
            onValue(checkData, (snapshot) => {
                resolve(snapshot.val()? true : false)
            })
		})
    }
    //モーダルウィンドウを開く際に実行
    const showDefaultModal = (message, agreeFlag = false) => {
        //以下の条件の時にTrueとなる
        //１．新規パスワードが一致している場合
        //２．agreeFlagがTrueの場合
        if(reactiveObj.newPassword1 === reactiveObj.newPassword2 || agreeFlag){
            //モーダルを表示
            reactiveModalObj.modalFlag= true
            //モーダル上の表示する文面
            reactiveModalObj.modalMessage = message ? message : `入力した内容でパスワードを変更します。<br><br>よろしいですか？`
            //モーダル部分を再描画
            reactiveModalObj.modalRenderkey++
        }
        else {
            alert(Error.errorResult('password mismatch', auth.currentUser))
        }
    }
    //モーダルウィンドウを閉じる際に実行
    const closeDefaultModal = (editFlag) => {
        //モーダルウィンドウを閉じる
        reactiveModalObj.modalFlag= false
        //以下の条件の場合にTrueとなる
        //１．変更に同意を押下している場合
        //２．オプションフラグ（がFalse）の場合
        if(editFlag && !reactiveModalObj.optionFlag) {
            //ローディング開始
            reactiveModalObj.loadingFlag = true
            //ログイン処理を行う
            signInWithEmailAndPassword(auth, reactiveObj.email, reactiveObj.password)
            // サインイン成功時
            .then(async (userCredential) => {
                //URLの企業コードが誤っている時
                if(!await checkCorporate()) {
                    alert(Error.errorResult('url or corporate mismatch', auth.currentUser))
                    reactiveModalObj.loadingFlag = false
                }
                else {
                    //利用者情報を取得
                    const user = userCredential.user;
                    const newPassword = reactiveObj.newPassword1
                    //パスワードの更新
                    updatePassword(user, newPassword)
                    //正常時
                    .then(() => {
                        //更新完了のモーダルを開くのでローディングを解除
                        reactiveModalObj.loadingFlag = false
                        showDefaultModal(`パスワードを変更しました。`, true)
                        //パスワード変更のメール通知
                        pushEmail(reactiveObj.email)
                        //オプションフラグを有効化（通常のモーダルと同じ表示）
                        reactiveModalObj.optionFlag = true
                        //入力された値をリセット
                        reactiveObj.email= ""
                        reactiveObj.password = ""
                        reactiveObj.newPassword1 = ""
                        reactiveObj.newPassword2 = ""
                    })
                    //エラー時
                    .catch((error) => {
                        //ローディング解除
                        reactiveModalObj.loadingFlag = false
                        alert(Error.errorResult(error.code, auth.currentUser, {'path': route.path}))
                    });
                }
                logOut()
            })
            // サインイン失敗時
            .catch((error) => {
                //ローディング解除
                reactiveModalObj.loadingFlag = false
                alert(Error.errorResult(error.code, auth.currentUser))
            });
        }
        //モーダル外を押下した時は何もしない
        if(!editFlag) {
            return
        }
        if(reactiveModalObj.optionFlag) {
            reactiveModalObj.optionFlag = false
        }
    }
    //全ての入力フォームが入力されたときに変更ボタンを活性化させる
    const inputSomeData = () => {
        //変更ボタンの活性化フラグ
        let equalFlag = false
        //全ての入力フォームが入力されたときに変更ボタンの活性化フラグをTrueにする
        if(reactiveObj.password && reactiveObj.newPassword1 && reactiveObj.newPassword2) {
                equalFlag = true
        }
        //活性判定フラグTrueなら変更ボタンを活性化そうでないなら非活性化
        equalFlag? document.getElementById('commonButton').disabled = false: document.getElementById('commonButton').disabled = true
    }
    //リアクティブオブジェクトの監視
    watch(reactiveObj, () => {
        inputSomeData()
    })
</script>

<template>
    <HeaderComponent></HeaderComponent>
    <main>
        <link rel="stylesheet" href="./togglePassword.css">
        <link href="https://use.fontawesome.com/releases/v5.6.1/css/all.css" rel="stylesheet">
        <div id="loadingDisplay" class="loading" v-show="reactiveModalObj.loadingFlag">
            <div class="load-text load-blink">処理中...</div>
        </div>
        <div class="userPassword">
            <div class="titleArea">
                <p class="title">利用者のパスワード変更</p>
            </div>
            <table class="commonTable">
                <tbody>
                    <tr>
                        <th></th>
                    </tr>
                    <tr>
                        <td class="firstTd notButtom">　登録メールアドレス</td>
                    </tr>
                    <tr>
                        <td class="firstTd notTop">
                            <input type="checkbox" class="checkPassword">
                            <div class="togglePassword">
                                <input type="text" class="hideText" v-model="reactiveObj.email">
                                <!--<input type="text" class="showText" v-model="reactiveObj.password">-->
                                <!--<label for="checkPassword" class="fa fa-eye"></label>-->
                                <!--<label for="checkPassword" class="fa fa-eye-slash"></label>-->
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="firstTd notButtom">　現在のパスワード</td>
                    </tr>
                    <tr>
                        <td class="firstTd notTop">
                            <input type="checkbox" class="checkPassword">
                            <div class="togglePassword">
                                <input type="password" class="hideText" v-model="reactiveObj.password">
                                <!--<input type="text" class="showText" v-model="reactiveObj.password">-->
                                <!--<label for="checkPassword" class="fa fa-eye"></label>-->
                                <!--<label for="checkPassword" class="fa fa-eye-slash"></label>-->
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="firstTd notButtom">　新しいパスワード</td>
                    </tr>
                    <tr>
                        <td class="firstTd notTop">
                            <input type="checkbox" class="checkPassword">
                            <div class="togglePassword">
                                <input type="password" class="hideText" v-model="reactiveObj.newPassword1">
                                <!--<input type="text" class="showText" v-model="reactiveObj.password">-->
                                <!--<label for="checkPassword" class="fa fa-eye"></label>-->
                                <!--<label for="checkPassword" class="fa fa-eye-slash"></label>-->
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="firstTd notButtom">　新しいパスワード（確認）</td>
                    </tr>
                    <tr>
                        <td class="firstTd notTop">
                            <input type="checkbox" class="checkPassword">
                            <div class="togglePassword">
                                <input type="password" class="hideText" v-model="reactiveObj.newPassword2">
                                <!--<input type="text" class="showText" v-model="reactiveObj.password">-->
                                <!--<label for="checkPassword" class="fa fa-eye"></label>-->
                                <!--<label for="checkPassword" class="fa fa-eye-slash"></label>-->
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="updateArea notButtom"><button type="button" id="commonButton" @click="showDefaultModal()" disabled>変更する</button></td>
                    </tr>
                    <tr>
                        <td class="notTop"><router-link :to="{name: 'userpasswordreset', query: {CorporateId: route.query.CorporateId}}">パスワードを忘れた方はこちら</router-link></td>
                    </tr>
                </tbody>
            </table>
            <DefaultModalComponent :key="reactiveModalObj.modalRenderkey" :modalMessage="reactiveModalObj.modalMessage" v-show="reactiveModalObj.modalFlag" @executeMethod="closeDefaultModal"></DefaultModalComponent>
        </div>
    </main>
</template>

<style scoped>
    .userPassword {
        padding: 0 20% 0 20%;
        background-color: #e7e6e6;
    }
    .commonTable tr:first-child>*:last-child {
        border-radius: 20px 20px 0 0;
    }
    .commonTable td {
        height: 75px;
    }
    .commonTable .updateArea {
        height: 150px;
    }
    @media (max-width: 1000px) {
        .userPassword {
            padding: 0 5% 0 5%;
        }
        #commonButton {
            width: 30vw;
            left: 17%;
        }
    }
</style>